import React, { ComponentProps, useState } from 'react'

import {
  FormGroup,
  FormText,
  Input,
  Radio,
  RadioGroup,
  Spacer
} from '@te-digi/styleguide'

const RadioGroupExample4 = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof RadioGroup>['value']>('1')
  const [inputValue1, setInputValue1] = useState('')
  const [inputValue2, setInputValue2] = useState('')

  return (
    <>
      <RadioGroup
        label="Valitse sopiva vaihtoehto"
        name="radio-group-example-4"
        onChange={e => setValue(e)}
        value={value}
      >
        <Radio value="1">Vaihtoehto 1</Radio>
        <Radio value="2">Vaihtoehto 2</Radio>
      </RadioGroup>
      <Spacer marginTop="md">
        <FormGroup>
          {value === '1' ? (
            <Input
              label="Lisätietoa vaihtoehdolle 1"
              onChange={event => setInputValue1(event.target.value)}
              value={inputValue1}
            />
          ) : (
            <Input
              label="Lisätietoa vaihtoehdolle 2"
              onChange={event => setInputValue2(event.target.value)}
              value={inputValue2}
            />
          )}
        </FormGroup>
      </Spacer>
      <FormText>Valittu: {value}</FormText>
    </>
  )
}

export { RadioGroupExample4 }
