import React, { ComponentProps, useState } from 'react'

import { FormText, Input, Radio, RadioGroup } from '@te-digi/styleguide'

const RadioGroupExample3 = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof RadioGroup>['value']>('1')
  const [inputValue1, setInputValue1] = useState('')
  const [inputValue2, setInputValue2] = useState('')

  return (
    <>
      <RadioGroup
        label="Valise sopiva vaihtoehto"
        onChange={e => setValue(e)}
        name="radio-group-example-3"
        value={value}
      >
        <Radio
          conditional={
            <Input
              label="Lisätietoa vaihtoehdolle 1"
              onChange={event => setInputValue1(event.target.value)}
              value={inputValue1}
            />
          }
          value="1"
        >
          Vaihtoehto 1
        </Radio>
        <Radio
          conditional={
            <Input
              label="Lisätietoa vaihtoehdolle 2"
              onChange={event => setInputValue2(event.target.value)}
              value={inputValue2}
            />
          }
          value="2"
        >
          Vaihtoehto 2
        </Radio>
      </RadioGroup>
      <FormText>Valittu: {value}</FormText>
    </>
  )
}

export { RadioGroupExample3 }
