import React, { ComponentProps, useState } from 'react'

import {
  FormText,
  Radio,
  RadioGroup,
  SmallText,
  Spacer
} from '@te-digi/styleguide'

const RadioGroupExample2 = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof RadioGroup>['value']>('1')

  return (
    <>
      <RadioGroup
        label="Valise sopiva vaihtoehto"
        onChange={e => setValue(e)}
        name="radio-group-example-2"
        value={value}
      >
        <Radio
          description={
            <SmallText noMargin>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
            </SmallText>
          }
          value="1"
        >
          Vaihtoehto 1 kuvauksella
        </Radio>
        <Spacer marginBottom="lg" />
        <Radio
          description={
            <SmallText noMargin>
              Quisque ligula eros ullamcorper quis, lacinia quis facilisis sed
              sapien.
            </SmallText>
          }
          value="2"
        >
          Vaihtoehto 2 kuvauksella
        </Radio>
      </RadioGroup>
      <FormText>Valittu: {value}</FormText>
    </>
  )
}

export { RadioGroupExample2 }
