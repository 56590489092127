import React, { ComponentProps, useState } from 'react'

import { Radio, RadioGroup } from '@te-digi/styleguide'

const RadioGroupErrorExample = () => {
  const [value, setValue] =
    useState<ComponentProps<typeof RadioGroup>['value']>('')

  return (
    <RadioGroup
      error="Error"
      label="Lorem ipsum dolor sit amet"
      onChange={e => setValue(e)}
      required
      value={value}
      name="radio-group-error-example"
    >
      <Radio value="1">Vaihtoehto 1</Radio>
      <Radio value="2">Vaihtoehto 2</Radio>
      <Radio value="3">Vaihtoehto 3</Radio>
    </RadioGroup>
  )
}

export { RadioGroupErrorExample }
