import React, { useState } from 'react'

import { FormGroup, FormText, Label, Radio } from '@te-digi/styleguide'

const RadioExample1 = () => {
  const [value, setValue] = useState('1')
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setValue(event.target.value)

  return (
    <>
      <FormGroup ariaLabelledby="radio-example-label-example-1">
        <Label id="radio-example-label-example-1">
          Donec placerat leo sit amet velit
        </Label>
        <Radio
          checked={value === '1'}
          id="radio1-1"
          name="radio-example-1"
          onChange={onChange}
          value="1"
        >
          Lorem ipsum dolor sit amet
        </Radio>
        <Radio
          checked={value === '2'}
          id="radio1-2"
          name="radio-example-1"
          onChange={onChange}
          value="2"
        >
          Sed posuere interdum sem
        </Radio>
        <Radio
          disabled
          id="radio1-3"
          name="radio-example-1"
          value="3"
        >
          Quisque ligula eros ullamcorper quis
        </Radio>
        <Radio
          disabled
          id="radio1-4"
          name="radio-example-1"
          value="4"
        >
          Lacinia quis facilisis sed sapien
        </Radio>
        <Radio
          checked={value === '5'}
          help="In vino veritas"
          helpButtonLabel="Alea yacta est"
          id="radio1-5"
          name="radio-example-1"
          onChange={onChange}
          required
          value="5"
        >
          Cogito ergo sum
        </Radio>
      </FormGroup>
      <FormText>Valittu: {value}</FormText>
    </>
  )
}

export { RadioExample1 }
