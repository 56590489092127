import tokens from '@te-digi/styleguide-tokens'
import { Color } from '@te-digi/styleguide/components/types'
import React from 'react'

import {
  Help,
  Paragraph,
  Radio,
  RadioGroup,
  ThumbDownIcon
} from '@te-digi/styleguide'

import styled from 'styled-components'

import { Code } from '../../components/Code'
import { Content } from '../../components/Content'
import { FormLayoutWrapper } from '../../components/FormLayoutWrapper'
import { Playground } from '../../components/Playground'
import { RadioExample1 } from '../../examples/react/RadioExample1'
import { RadioGroupErrorExample } from '../../examples/react/RadioGroupErrorExample'
import { RadioGroupExample1 } from '../../examples/react/RadioGroupExample1'
import { RadioGroupExample2 } from '../../examples/react/RadioGroupExample2'
import { RadioGroupExample3 } from '../../examples/react/RadioGroupExample3'
import { RadioGroupExample4 } from '../../examples/react/RadioGroupExample4'
import { Section } from '../../components/Section'
import CustomControlHTMLExample from '../../examples/html/CustomControlHTMLExample'
import LabelHTMLExample from '../../examples/html/LabelHTMLExample'

interface CustomBulletProps {
  icon?: JSX.Element
  color: Color
}

const StyledBullet = styled.span<{
  $color: CustomBulletProps['color']
}>`
  background-color: ${props => tokens.color[props.$color || 'neutral-3']};
  border-radius: ${tokens.radius.circle};
  display: inline-block;
  margin-right: ${tokens.space.xs};
  height: ${tokens.space.lg};
  width: ${tokens.space.lg};
  padding: ${tokens.space.xxxs} ${tokens.space.xxs};
`

const CustomBullet = ({ icon, color }: CustomBulletProps) => (
  <StyledBullet $color={color}>{icon}</StyledBullet>
)

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="RadioGroup"
    components={[
      { component: RadioGroup },
      { component: Radio, restElement: 'input' }
    ]}
  >
    <Section>
      <Paragraph>
        <Code>RadioGroup</Code>-komponentilla voi luoda <Code>Radio</Code>
        -ryhmiä. <Code>RadioGroup</Code> luo kontekstin, joka luo jokaiselle sen
        sisällä olevalle <Code>Radio</Code>-komponentille <Code>checked</Code>,{' '}
        <Code>name</Code>, <Code>onChange</Code> ja <Code>required</Code>{' '}
        -propsit. Myös virheviestit voi näyttää <Code>RadioGroupin</Code>{' '}
        avulla.
      </Paragraph>
      <Help>
        <Paragraph noMargin>
          <Code>RadioGroup</Code>ia ei tarvitse erikseen ympäröidä{' '}
          <Code>FormGroup</Code>illa, komponentti tuottaa sen automaattisesti.
        </Paragraph>
      </Help>

      <Playground
        example={RadioGroupExample1}
        WrapperComponent={FormLayoutWrapper}
      />

      <Playground
        example={RadioGroupExample2}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Virheviesti">
      <Playground
        example={RadioGroupErrorExample}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>

    <Section title="Radio FormGroupissa">
      <Paragraph>
        <Code>Radio</Code>-komponentti toimii myös ilman <Code>RadioGroup</Code>
        -komponenttia, mutta vaatii enemmän manuaalista huolehtimista
        propseista.
      </Paragraph>
      <Playground
        example={RadioExample1}
        WrapperComponent={FormLayoutWrapper}
      />
      <Playground
        format="html"
        WrapperComponent={FormLayoutWrapper}
      >
        <div
          aria-labelledby="radio-html-example-label-example-1"
          className="form-group mb-0"
          role="group"
        >
          <LabelHTMLExample
            as="p"
            id="radio-html-example-label-example-1"
          >
            Donec placerat leo sit amet velit
          </LabelHTMLExample>
          <CustomControlHTMLExample
            type="radio"
            defaultChecked
            id="radio-html-example-1-1"
            name="radio-html-example-1"
            value="1"
          >
            Lorem ipsum dolor sit amet
          </CustomControlHTMLExample>
          <CustomControlHTMLExample
            type="radio"
            id="radio-html-example-1-2"
            name="radio-html-example-1"
            value="2"
          >
            Sed posuere interdum sem
          </CustomControlHTMLExample>
          <CustomControlHTMLExample
            type="radio"
            id="radio-html-example-1-3"
            name="radio-html-example-1"
            value="1"
            disabled
          >
            Quisque ligula eros ullamcorper quis
          </CustomControlHTMLExample>
          <CustomControlHTMLExample
            disabled
            type="radio"
            id="radio-html-example-1-4"
            name="radio-html-example-1"
            value="2"
          >
            Lacinia quis facilisis sed sapien
          </CustomControlHTMLExample>
        </div>
      </Playground>
    </Section>

    <Section title="Ehdolliset lisäkentät">
      <Paragraph>
        <Code>RadioGroup</Code>in sisään ei lähtökohtaisesti tule lisätä
        valintojen väliin lisäkenttiä. Valintojen lisäkentät lisätään{' '}
        <Code>RadioGroup</Code>in perään.
      </Paragraph>
      <Playground
        example={RadioGroupExample4}
        WrapperComponent={FormLayoutWrapper}
      />

      <Paragraph>
        <CustomBullet
          color="danger"
          icon={<ThumbDownIcon color="white" />}
        />
        Ei suositeltava esimerkki, jossa käytössä <Code>conditional</Code>-prop:
      </Paragraph>
      <Playground
        example={RadioGroupExample3}
        WrapperComponent={FormLayoutWrapper}
      />
    </Section>
  </Content>
)

export default Page
